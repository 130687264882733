import React, { Component } from "react";

export default class BlankPage extends Component {
  render() {
    return (
      <div>
        <div align="center" style={{ padding: "150px" }}>
          <h1>DTMS</h1>
          <h3>ระบบบริหารการจัดการศูนย์สอนขับรถขั้นพื้นฐาน</h3>
        </div>
      </div>
    );
  }
}
